import React, { ButtonHTMLAttributes } from 'react';
import './Button.scss';

const Button = (props) => (
	<button className={'Button'} {...props}>
		{props.children}
	</button>
);

export const SecondaryButton = ({ color, ...props }: ButtonHTMLAttributes<HTMLButtonElement>) => (
	<button className="Button Secondary" style={{ color }} {...props}>
		{props.children}
	</button>
);

export const TertiaryButton = (props) => (
	<button className="Button Tertiary" {...props}>
		{props.children}
	</button>
);
export default Button;
