import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { PresenceConsumer } from '../contexts/Presence';
import Avatar from './Avatar';
import './PagePresences.scss';

const PagePresences = () => (
	<PresenceConsumer>
		{({ user_presences }) =>
			user_presences.length > 0 ? (
				<div className="PagePresences">
					{user_presences.slice(0, 3).map((u) => (
						<div key={u.id}>
							<Avatar user={u.user} />
						</div>
					))}
					{user_presences.length > 3 ? (
						<div className="Rest">
							+<FontAwesomeIcon icon={faUser} size="sm" />
							{user_presences.length - 3}
						</div>
					) : (
						''
					)}
				</div>
			) : null
		}
	</PresenceConsumer>
);

export default PagePresences;
