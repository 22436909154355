import React, { useEffect } from 'react';
import config from '~/src/lib/config';
import styles from '~/src/lib/styles';
import './Html.scss';

export default function Html({
	assets = {},
	children = null,
	title = 'Overtime',
}: {
	assets: { [key: string]: any };
	children: React.ReactNode;
	title: string;
}) {
	const description =
		"Overtime is the leading brand for the next generation of sports fans. As both a publisher and an influencer, we've built the inclusive world of sports we all wish was the norm by giving a platform to our community to be seen AND heard.";

	useEffect(() => {
		//Mobile browsers lie about vh so we're stuck with this nonsense
		window.onresize = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
		window.onresize = () => {
			//All this is necessary to handle scrollbar width problems
			document.documentElement.style.setProperty('--app-width', `${document.body.clientWidth}px`);
		};
		const observer = new ResizeObserver((_el) => window.onresize(null));
		observer.observe(document.body);

		window.onresize(null);
		return () => {
			window.onresize = null;
			observer.unobserve(document.body);
		};
	}, []);

	return (
		<html lang="en">
			<head>
				<title>Overtime</title>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta charSet="UTF-8" />
				<meta name="google-site-verification" content="_Npfn_L-weRdy7khF5vLyZVtGDv7o59P15vRfRpuFDw" />
				<meta name="google-site-verification" content="2kA3U3XEwCVR8zHoKOR9o12DITn1nJApUIJqQ1CKH-s" />
				<meta name="google-site-verification" content="AKheGLF0n028K_um_R3PAQHuImh2e_WEe3RXqzo5NaA" />
				<meta property="fb:app_id" content={config('OVERTIME_FB_APP')} />
				<meta property="og:site_name" content="OVERTIME" />
				<meta property="og:description" content={description} />
				{/* <meta property="og:image" content={`https://overtime.tv${imgOvertime_1024Png}`} /> */}
				<meta name="robots" content="noindex" />
				<meta name="description" content={description} />
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				{/* <link rel="manifest" href="/site.webmanifest" /> */}
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color={styles.BLACK} />
				<meta name="msapplication-TileColor" content={styles.BLACK} />
				<meta name="theme-color" content={styles.BLACK} />

				{/* <script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: `{
                        "@context": "http://schema.org",
                        "@type": "Organization",
                        "name": "Overtime",
                        "url": "https://overtime.tv",
                        "logo": "https://overtime.tv${imgOvertime_1024Png}",
                        "sameAs" : [ "https://www.facebook.com/getovertime", "https://www.instagram.com/overtime", "https://twitter.com/overtime", "https://www.youtube.com/channel/UCPHKMJbis1c0R7d7WJQ35tA"]
                        }`,
					}}
				/> */}
				<meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
				<link
					href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&amp;family=Source+Code+Pro:wght@400;600;700&amp;family=Oswald&amp;display=block"
					rel="stylesheet"
				/>
				<link rel="stylesheet" href="/public/fortawesome.css" />
				<link rel="stylesheet" href="/public/app.css" />
				{Object.values(assets)
					.filter((a) => a.endsWith('css'))
					.map((a) => (
						<link rel="stylesheet" key={a} href={a} />
					))}

				<title>{title}</title>
				{/* <!-- Google tag (gtag.js) --> */}
				<script async src="https://www.googletagmanager.com/gtag/js?id=G-QQ4SJYYWWK"></script>
				<script
					dangerouslySetInnerHTML={{
						__html: `
							window.dataLayer = window.dataLayer || [];
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('config', 'G-QQ4SJYYWWK');`,
					}}
				></script>
			</head>
			<body>
				<noscript
					dangerouslySetInnerHTML={{
						__html: `<b>Enable JavaScript to run this app.</b>`,
					}}
				/>
				<div id="root">{children}</div>
				<script
					dangerouslySetInnerHTML={{
						__html: `window.assetManifest = ${JSON.stringify(assets)};window.fetch = window.fetch.bind(window);`,
					}}
				/>
				<script src="/public/app.js" />
			</body>
		</html>
	);
}
