import React from 'react';
import Header from '~/src/components/Header';
import Nav, { NavProvider } from '~/src/components/Nav';
import { AuthenticationProvider } from '~/src/contexts/Authentication';
import { PresenceProvider } from '~/src/contexts/Presence';
import './Layout.scss';

const Layout = ({ children }) => (
	<AuthenticationProvider>
		<PresenceProvider>
			<NavProvider>
				<section className="Layout">
					<Header />
					<div className="ContentContainer">
						<Nav />
						{children}
					</div>
				</section>
			</NavProvider>
		</PresenceProvider>
	</AuthenticationProvider>
);
export default Layout;
