import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import './Spinner.scss';

export default function Spinner({
	color = 'black',
	size = '1x',
	icon = faSpinner,
	label,
}: {
	color?: string;
	size?: SizeProp;
	icon?: IconDefinition;
	label?: string;
}) {
	const [isSpinning, setIsSpinning] = useState(false);
	useEffect(() => {
		setTimeout(() => setIsSpinning(true), 100);
	}, []);

	return (
		<div className="Spinner">
			<FontAwesomeIcon icon={icon} color={color} size={size} spin={isSpinning} />
			{label ? <div className="Caption">{label}</div> : null}
		</div>
	);
}
