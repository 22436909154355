const styles = {
	//COLORS
	BRAND_PRIMARY: '#141923',
	BRAND_PRIMARY_DARK: '#0c111b',
	BRAND_PRIMARY_MODAL: 'rgba(20,25,35,0.98)',
	BRAND_PRIMARY_OVERLAY: 'rgba(20,25,35,0.7)',
	BRAND_SECONDARY: '#FF6E40',
	BRAND_LINK: '#0FB5A7',
	OFFBLACK: '#333',
	BLACK: '#1e1e1e',
	TRUE_BLACK: '#000',
	WHITE: '#ffffff',
	OFFWHITE: '#efefef',
	DARK_GRAY: '#373737',
	GRAY: '#878787',
	LIGHT_GRAY: '#cccccc',
	GREEN: '#1FEB9E',
	RED: '#ff5549',
	BLUE: '#128DFF',
	YELLOW: '#fff354',
	PURPLE: '#7D5DFC',
	DESATURATED_WHITE_BORDER: 'rgba(255,255,255,0.2)',
	DESATURATED_WHITE_TEXT: 'rgba(255,255,255,0.4)',
	DESATURATED_WHITE_BACKGROUND: 'rgba(255,255,255,0.8)',
	DESATURATED_BLACK_BORDER: 'rgba(0,0,0,0.1)',
	DESATURATED_BLACK_TEXT: 'rgba(0,0,0,0.4)',
	DESATURATED_BLACK_BACKGROUND: 'rgba(0,0,0,0.3)',
	SHADOW: 'rgba(0,0,0,0.25)',
	INNER_SHADOW: 'rgba(0,0,0,0.05)',
	//FONTS
	FONT_MAIN: 'Source Sans Pro, Helvetica Neue, Helvetica, sans-serif',
	FONT_HIGHLIGHT: 'Oswald, Helvetica Neue, Helvetica, sans-serif',
	FONT_MONO: 'Source Code Pro, monospace',
	SEMIBOLD: '600',
	BOLD: '700',
	//GRID
	GRID_GAP: 'var(--grid-gap)',
	GRID_GAP_QUARTER: 'calc(var(--grid-gap) / 4)',
	GRID_GAP_HALF: 'calc(var(--grid-gap) / 2)',
	GRID_GAP_2X: 'calc(var(--grid-gap) * 2)',
	GRID_GAP_3X: 'calc(var(--grid-gap) * 3)',
	GRID_GAP_4X: 'calc(var(--grid-gap) * 4)',
	GRID_GAP_6X: 'calc(var(--grid-gap) * 6)',
	ROW_GAP: 'var(--row-gap)',
	MOBILE: '@media (max-width: 640px)',
	TABLET: '@media (max-width: 990px)',
	LAPTOP: '@media (max-width: 1440px)',
	DESKTOP: '@media (min-width: 1440px)',
	CONTENT_WIDTH: '1400px',
};
export default styles;
