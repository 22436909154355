import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import wait from '~/src/overtime-lib/src/lib/wait';
import config from '../lib/config';
import { User } from './User';

export type UserType = User & {
	id: string;
	username: string;
	roles?: [string];
};

export type JoinChannelOptions = {
	channel: string;
	onMessage: (message: object) => void;
};

type SocketReadyState = WebSocket['OPEN'] | WebSocket['CLOSED'] | WebSocket['CLOSING'] | WebSocket['CONNECTING'];
export type ContextType = {
	isVerifying: boolean;
	currentUser?: UserType;
	logout: () => Promise<void> | void;
	request?: ({
		path,
		method,
		body,
		json,
	}: {
		path: any;
		method?: string;
		body?: any;
		json?: boolean;
	}) => Promise<any> | void;
	token?: string;
	setToken: (token: any, verify?: boolean) => Promise<void> | void;
	updateUser: (update: any) => Promise<void> | void;
	socketReadyState: SocketReadyState;
	joinChannel: (options: JoinChannelOptions) => void;
	canAccess: (resource: string) => boolean;
};

const defaultContext: ContextType = {
	isVerifying: true,
	currentUser: null,
	logout: () => {},
	request: null,
	setToken: () => {},
	updateUser: () => {},
	joinChannel: () => {},
	canAccess: () => false,
	socketReadyState: typeof WebSocket === 'undefined' ? null : WebSocket.CLOSED,
};

export const AuthenticationContext = React.createContext(defaultContext);
const { Provider, Consumer } = AuthenticationContext;

export const AuthenticationProvider = ({ children }) => {
	const [user, setUser] = useState<UserType>();
	const [token, setToken] = useState<string>();
	const [shouldVerify, setShouldVerify] = useState(false);
	const [isVerifying, setIsVerifying] = useState(!user);

	useEffect(() => {
		if (typeof localStorage !== 'undefined') {
			if (localStorage.getItem('token') === 'null' || localStorage.getItem('token') === 'undefined') {
				//Bad value stored, logout and reset
				logout();
			}
			if (!_.isNil(localStorage.getItem('token'))) {
				setToken(localStorage.getItem('token'));
				setUser({ roles: [], ...JSON.parse(localStorage.getItem('user')) });
			}
			setShouldVerify(true);
		}
	}, []);

	useEffect(() => {
		if (_.isNil(user)) {
			return;
		}
		localStorage.setItem('user', JSON.stringify(user));
	}, [user]);

	const logout = async () => {
		setToken(null);
		setUser(null);
		localStorage.removeItem('token');
		localStorage.removeItem('user');
	};

	const request = async ({ path, method = 'GET', body, json = true }) => {
		const host = config('OVERTIME_BACKEND_URL');
		const response = await fetch(`${host}${path}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			method,
			body: JSON.stringify(body),
		});
		if (json) {
			return await response.json();
		} else {
			return response;
		}
	};

	const setUserToken = async (token, verify = true) => {
		if (_.isNil(token)) {
			debugger;
		}
		setToken(token);
		setShouldVerify(verify);
	};

	const canAccess = (role) => {
		return (
			(user?.roles?.length ?? 0 > 0) &&
			(user.roles.includes('admin') || // Admins
				!!user.roles.includes(role?.split('.').shift()) || // "All" permissions on role (e.g. 'users' has access to 'users.read')
				!!user.roles.includes(role)) // Specific permissions for role (e.g. 'users.update' has access to 'users.update')
		);
	};

	useEffect(() => {
		if (_.isNil(token)) {
			return;
		}
		localStorage.setItem('token', token);
	}, [token]);

	useEffect(() => {
		if (shouldVerify) {
			verifyToken();
		}
	}, [token, shouldVerify]);

	const verifyToken = async () => {
		if (_.isEmpty(token)) {
			setIsVerifying(false);
			return;
		}
		setIsVerifying(true);
		const response = await fetch(`${config('OVERTIME_AUTH_URL')}/api/auth/refresh_token`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});

		if (response.ok && response.status === 200) {
			const json = await response.json();
			if (!json.data.user || !json.token || json.token === 'null') {
				setIsVerifying(false);
				logout();
				return;
			}
			//@ts-ignore
			window.gtag?.('config', 'GA_MEASUREMENT_ID', {
				user_id: json.data.user.id,
			});
			//@ts-ignore
			window.gtag?.('set', 'user_properties', {
				username: json.data.user.username,
			});
			//@ts-ignore
			window.mixpanel?.identify(json.data.user.id);
			//@ts-ignore
			window.mixpanel?.people.set({ username: json.data.user.username });
			setShouldVerify(false);
			setToken(json.token);

			const user = (
				await (await fetch(`${config('OVERTIME_BACKEND_URL')}/api/users/${json.data.user.id}?nocache=true`)).json()
			).user;
			setUser({ ...{ roles: [] }, ...user, ...json.data.user });

			await wait(100);
			setIsVerifying(false);
		} else {
			setIsVerifying(false);
			logout();
		}
		setShouldVerify(false);
	};

	const updateUser = async (update) => {
		try {
			const { user } = await request({
				path: `/api/writer/users/${update.id}`,
				method: 'PUT',
				body: update,
			});
			debugger;
			setUser(user);
		} catch (error) {
			console.error(error);
		}
	};

	const socketRef = useRef<WebSocket>();
	const channelSubscribersRef = useRef<{}>({});
	const lastPingRef = useRef<Date>(new Date());
	const lastPongRef = useRef<Date>(new Date());

	const connectSocket = () => {
		if (typeof WebSocket === 'undefined') {
			return;
		}
		if (!token || token === 'null') {
			return;
		}
		const socket = new WebSocket(`wss://notifications.itsovertime.com?token=${token}`);
		// const socket = new WebSocket(`ws://localhost:8081?token=${token}`);
		socket.onclose = () => {
			if (socketRef.current !== socket) {
				return;
			}
		};
		socket.onmessage = (e) => {
			if (e.data === 'pong') {
				lastPongRef.current = new Date();
				return;
			}
			const data = JSON.parse(e.data);
			if (data.channel) {
				channelSubscribersRef.current[data.channel]?.forEach((fn) => fn(JSON.parse(data.data)));
			}
		};
		socketRef.current = socket;
	};
	const [socketState, setSocketState] = useState(socketRef.current?.readyState);
	useEffect(() => {
		const id = setInterval(() => {
			if (socketRef.current?.readyState === WebSocket?.OPEN) {
				lastPingRef.current = new Date();
				socketRef.current?.send('ping');

				if (lastPingRef.current.getTime() - lastPongRef.current.getTime() > 3 * 1000) {
					// socketRef.current?.close();
					setSocketState(WebSocket?.CLOSED);
				} else {
					setSocketState(WebSocket?.OPEN);
				}
			} else {
				if (socketRef.current?.readyState !== WebSocket?.CONNECTING) {
					connectSocket();
				}
				setSocketState(socketRef.current?.readyState);
			}
		}, 1000);

		return () => {
			clearInterval(id);
		};
	}, []);
	useEffect(() => {
		connectSocket();
		return () => {
			socketRef.current?.close();
			socketRef.current = null;
		};
	}, [token]);
	useEffect(() => {
		return () => {
			socketRef.current?.close();
		};
	}, []);

	const joinChannel = ({ channel, onMessage }: JoinChannelOptions) => {
		if (socketRef.current?.readyState === WebSocket?.OPEN) {
			socketRef.current?.send(JSON.stringify({ data: { action: 'join_channel', channel, is_user_channel: false } }));
			console.log('Joined', channel);
		} else {
			setTimeout(() => {
				joinChannel({ channel, onMessage });
			}, 1000);
			return;
		}

		channelSubscribersRef.current = {
			...channelSubscribersRef.current,
			[channel]: [...(channelSubscribersRef.current[channel] || []), onMessage],
		};
	};

	const value = {
		logout,
		request,
		token,
		setToken: setUserToken,
		updateUser,
		currentUser: user,
		canAccess,
		isVerifying,
		joinChannel,
		socketReadyState: socketState as SocketReadyState,
	};
	return <Provider value={value}>{children}</Provider>;
};

export const AuthenticationConsumer = (C: React.ConsumerProps<ContextType>) => <Consumer>{C.children}</Consumer>;

export const withAuthentication = (Component) => (props) => (
	<AuthenticationConsumer>
		{(authenticationProps) => <Component {...props} {...authenticationProps} />}
	</AuthenticationConsumer>
);
