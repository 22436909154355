import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withLocation } from '../components/WithLocation';
import { JoinChannelOptions, withAuthentication } from './Authentication';

type ContextType = {
	user_presences: UserPresence[];
};

type UserPresence = {
	id: string;
	path: string;
	user_id: string;
	user: {
		id: string;
		username: string;
	};
};
const defaultContext: ContextType = {
	user_presences: [],
};

const { Provider, Consumer } = React.createContext(defaultContext);

const _PresenceProvider = ({ location, query, children, currentUser, request, joinChannel }) => {
	const [userPresences, setUserPresences] = useState<{ [key: string]: UserPresence }>({});
	useEffect(() => {
		const options: JoinChannelOptions = {
			channel: 'admin::user_presences',
			onMessage: (data) => {
				const { user_presence } = data as any;
				setUserPresences((u) => ({ ...u, [user_presence.id]: user_presence }));
			},
		};
		// console.log('Joining presence channel');
		joinChannel(options);
	}, []);

	useEffect(() => {
		if (!currentUser?.id) {
			return;
		}

		const fetch = async () => {
			const { user_presences } = await request({ path: '/api/user_presences/v1/bundle_identifier/admin.overtime.tv' });
			setUserPresences(_.keyBy(user_presences, 'id'));
		};
		// console.log('Fetching presences');
		fetch();
	}, [currentUser?.id]);

	const isFocused = useRef(true);
	useEffect(() => {
		if (typeof window === 'undefined') {
			isFocused.current = false;
			return;
		}

		const onBlur = () => {
			isFocused.current = false;
		};
		const onFocus = () => {
			isFocused.current = true;
		};
		window.addEventListener('blur', onBlur);
		window.addEventListener('focus', onFocus);
		return () => {
			window.removeEventListener('blur', onBlur);
			window.removeEventListener('focus', onFocus);
		};
	}, []);

	useEffect(() => {
		if (!currentUser?.id) {
			return;
		}
		const postPresence = () => {
			if (!isFocused.current) {
				return;
			}
			// console.log('Posting presence');
			request({
				path: '/api/writer/user_presences/v1',
				method: 'POST',
				body: { bundle_identifier: 'admin.overtime.tv', application_name: 'Overtime Admin', path: location.pathname },
			});
		};
		const id = setInterval(postPresence, 5 * 1000);
		postPresence();

		return () => {
			clearInterval(id);
		};
	}, [currentUser]);

	const value: ContextType = {
		user_presences: _.flow(_.values, (x) => _.filter(x, (p) => p.path === location.pathname))(userPresences),
	};
	return <Provider value={value}>{children}</Provider>;
};

export const PresenceProvider = withLocation(withAuthentication(_PresenceProvider));

export const PresenceConsumer = (C: React.ConsumerProps<ContextType>) => <Consumer>{C.children}</Consumer>;
