export { default as imgOvertime_1024Png } from 'url:~/src/images/Overtime@1024.png';
export { default as imgBookmarkPng } from 'url:~/src/images/bookmark.png';
export { default as imgCoinSvg } from 'url:~/src/images/coin.svg';
export { default as imgEventDefaultPng } from 'url:~/src/images/event_default.png';
export { default as imgFolderIconSvg } from 'url:~/src/images/folder_icon.svg';
export { default as imgIsFavoriteSvg } from 'url:~/src/images/is_favorite.svg';
export { default as imgIsFunnySvg } from 'url:~/src/images/is_funny.svg';
export { default as imgIsOvertimeHandsSvg } from 'url:~/src/images/is_overtime_hands.svg';
export { default as imgNavAdminsSvg } from 'url:~/src/images/nav/admins.svg';
export { default as imgNavArchivesSvg } from 'url:~/src/images/nav/archives.svg';
export { default as imgNavAvatarsSvg } from 'url:~/src/images/nav/avatars.svg';
export { default as imgNavBasketballSvg } from 'url:~/src/images/nav/basketball.svg';
export { default as imgNavCameraAccessSvg } from 'url:~/src/images/nav/camera_access.svg';
export { default as imgNavEnhancementsSvg } from 'url:~/src/images/nav/enhancements.svg';
export { default as imgNavEventsSvg } from 'url:~/src/images/nav/events.svg';
export { default as imgNavExperienceLevelsSvg } from 'url:~/src/images/nav/experience_levels.svg';
export { default as imgNavFactualQuestionsSvg } from 'url:~/src/images/nav/factual_questions.svg';
export { default as imgNavFightsSvg } from 'url:~/src/images/nav/fights.svg';
export { default as imgNavFootballSvg } from 'url:~/src/images/nav/football.svg';
export { default as imgNavHeadlineTagsSvg } from 'url:~/src/images/nav/headline_tags.svg';
export { default as imgNavHeadlinesSvg } from 'url:~/src/images/nav/headlines.svg';
export { default as imgNavLeaderboardSvg } from 'url:~/src/images/nav/leaderboard.svg';
export { default as imgNavPlayerStatisticsSvg } from 'url:~/src/images/nav/player_statistics.svg';
export { default as imgNavPlayersSvg } from 'url:~/src/images/nav/players.svg';
export { default as imgNavSeasonsSvg } from 'url:~/src/images/nav/seasons.svg';
export { default as imgNavShopifySvg } from 'url:~/src/images/nav/shopify.svg';
export { default as imgNavTeamsSvg } from 'url:~/src/images/nav/teams.svg';
export { default as imgNavVideoUploadsSvg } from 'url:~/src/images/nav/video_uploads.svg';
export { default as imgNavVideographersSvg } from 'url:~/src/images/nav/videographers.svg';
export { default as imgNavVideosSvg } from 'url:~/src/images/nav/videos.svg';
export { default as imgOtWatermarkSvg } from 'url:~/src/images/ot_watermark.svg';
export { default as imgOvertimeIconSvg } from 'url:~/src/images/overtime_icon.svg';
export { default as imgOvertimeLogotypeSvg } from 'url:~/src/images/overtime_logotype.svg';
export { default as imgProvingGroundPlaceholderPng } from 'url:~/src/images/proving_ground_placeholder.png';
export { default as imgSectionIphoneSvg } from 'url:~/src/images/section_iphone.svg';
export { default as imgSectionOt7Svg } from 'url:~/src/images/section_ot7.svg';
export { default as imgSectionOteSvg } from 'url:~/src/images/section_ote.svg';
export { default as imgSectionScoresSvg } from 'url:~/src/images/section_scores.svg';
export { default as imgSectionUsersSvg } from 'url:~/src/images/section_users.svg';
