import React from 'react';
import Button from '~/src/components/Button';
import Html from '~/src/components/Html';
import Layout from '~/src/components/Layout';
import PageHeader from '~/src/components/PageHeader';
import Picture from '~/src/components/Picture';

/*
Add all shared elements here to fix Parcel being dumb
This component should never actually be used
*/

const Common = () => (
	<>
		{/* Parcel is trash and doesn't bundle CSS for components inside contexts */}
		<Html assets={{}} children={''} title={''} />
		<PageHeader />
		<Button href={undefined}>Hi</Button>
		<Picture src={''} alt={''} />
		<Layout children={undefined} />
	</>
);

export default Common;
