import React from 'react';
import './Avatar.scss';

const Avatar = ({ user }: { user: { name?: string; username?: string }; css?: any }) => (
	<img
		className="Avatar"
		title={user?.name && user?.name !== '' ? user?.name : user?.username}
		src={`https://api.itsovertime.com/api/users/username/${user?.username}/pic`}
	/>
);

export default Avatar;
