import React, { useCallback, useEffect, useState } from 'react';
import config from '../lib/config';
import styles from '../lib/styles';
import './Picture.scss';

type DataType = {
	src: string;
	alt: string;
	mobileWidth?: number;
	width?: number;
};

const Picture: React.FC<DataType> = ({
	src,
	alt,
	mobileWidth = 420,
	width = Math.round((parseInt(styles.CONTENT_WIDTH) / 3) * 2),
}) => {
	const [observer, setObserver] = useState<IntersectionObserver>();
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const observer = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach((e) => {
						if (e.isIntersecting) {
							const mobileWidth = parseInt((e.target as HTMLElement).dataset.mobileWidth);
							const width = parseInt((e.target as HTMLElement).dataset.width);
							// return;
							Array.from(e.target.children).forEach(
								(s: HTMLSourceElement) =>
									(s.srcset = s.srcset
										// .replace('width=16', `width=${s.media ? width * 2 : mobileWidth * 2}`)
										.replace('width=16', `width=${s.parentElement.clientWidth * 2}`)
										.replace('effect=singlecolor', '')),
							);
						}
					});
				},
				{ rootMargin: '100%' },
			);
			setObserver(observer);
		}
	}, []);

	const pictureRef = useCallback(
		(picture) => {
			const currentObserver = observer;
			if (!currentObserver) {
				return;
			}
			if (!picture) {
				return;
			}
			currentObserver.observe(picture);
		},
		[observer],
	);

	const url = new URL(src);

	let srcset;
	if (url.hostname === 'images.itsovertime.com' || url.hostname === 'images.overtime.tv') {
		srcset = src;
	} else if (url.hostname === 'i.ytimg.com') {
		srcset = `${config('OVERTIME_IMAGES_CDN')}/youtube${url.pathname}`;
	} else if (url.hostname.includes('cdninstagram.com')) {
		srcset = `${config('OVERTIME_IMAGES_CDN')}/instagram/${encodeURIComponent(
			typeof btoa !== 'undefined' ? btoa(src) : new Buffer(src).toString('base64'),
		)}`;
	}
	return (
		<div className="Picture">
			<picture ref={pictureRef} data-mobile-width={mobileWidth} data-width={width}>
				<source
					type="image/webp"
					srcSet={`${srcset}?format=webp&width=16&effect=singlecolor`}
					media={`all and @include mixins.desktop`}
				/>
				<source
					type="image/jpeg"
					srcSet={`${srcset}?width=16&effect=singlecolor`}
					media={`all and @include mixins.desktop`}
				/>
				<source type="image/webp" srcSet={`${srcset}?${['format=webp', 'width=16', 'effect=singlecolor'].join('&')}`} />
				<source type="image/jpeg" srcSet={`${srcset}?${['width=16', 'effect=singlecolor'].join('&')}`} />
				<img alt={alt} src={srcset} />
			</picture>
		</div>
	);
};
export default Picture;
