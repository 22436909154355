import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import './App.scss';
import Html from './Html';

export default function App({ assets = {}, children = null }) {
	return (
		<ErrorBoundary FallbackComponent={Error}>
			<Html assets={assets} title="Hello">
				<ErrorBoundary FallbackComponent={Error}>{children}</ErrorBoundary>
			</Html>
		</ErrorBoundary>
	);
}

function Error({ error }) {
	return (
		<div>
			<h1 style={{ color: 'white' }}>Application Error</h1>
			<pre style={{ color: 'white', whiteSpace: 'pre-wrap' }}>{error.stack}</pre>
		</div>
	);
}
