import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Spinner from './Spinner';
import './Suspended.scss';

const Suspended = ({
	size,
	icon,
	label,
	children,
}: {
	size?: SizeProp;
	icon?: IconDefinition;
	label?: string;
	children: ReactNode;
}) => (
	<ErrorBoundary
		fallback={
			<p className="SuspenseError">
				<FontAwesomeIcon icon={faTriangleExclamation} /> Unknown error.
			</p>
		}
	>
		<Suspense
			fallback={
				<div className="Suspended">
					<Spinner icon={icon} label={label} size={size} />
				</div>
			}
		>
			{children}
		</Suspense>
	</ErrorBoundary>
);

export default Suspended;
