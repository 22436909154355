import React from 'react';
import './PageHeader.scss';
import PagePresences from './PagePresences';

const PageHeader = ({ heading }: { heading?: string }) => (
	<div className="PageHeader">
		<h1>{heading}</h1>
		<PagePresences />
	</div>
);
export default PageHeader;
