import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import React, { createElement, lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';
import Common from '~/src/components/Common';
import Layout from '~/src/components/Layout';
import Suspended from '~/src/components/Suspended';

const Index = lazy(() => import('~/src/components/pages/Index'));

const Videos = lazy(() => import('~/src/components/pages/Videos'));
const Video = lazy(() => import('~/src/components/pages/Video'));
const VideosUpload = lazy(() => import('~/src/components/pages/VideosUpload'));
const VideoGroups = lazy(() => import('~/src/components/pages/VideoGroups'));
const VideoGroup = lazy(() => import('~/src/components/pages/VideoGroup'));
const VideoGroupPublic = lazy(() => import('~/src/components/pages/VideoGroupPublic'));
const VideosPublic = lazy(() => import('~/src/components/pages/VideosPublic'));
const Events = lazy(() => import('~/src/components/pages/Events'));
const Event = lazy(() => import('~/src/components/pages/Event'));
const CameraAccess = lazy(() => import('~/src/components/pages/CameraAccess'));
const CameraUsers = lazy(() => import('~/src/components/pages/CameraUsers'));
const Items = lazy(() => import('~/src/components/pages/Items'));
const Item = lazy(() => import('~/src/components/pages/Item'));
const Videographers = lazy(() => import('~/src/components/pages/Videographers'));
const Videographer = lazy(() => import('~/src/components/pages/Videographer'));

const Users = lazy(() => import('~/src/components/pages/Users'));
const User = lazy(() => import('~/src/components/pages/User'));
const AdminAccess = lazy(() => import('~/src/components/pages/AdminAccess'));

const OteScoutingProspects = lazy(() => import('~/src/components/pages/OteScoutingProspects'));
const OteScoutingProspect = lazy(() => import('~/src/components/pages/OteScoutingProspect'));
const OteScoutingReports = lazy(() => import('~/src/components/pages/OteScoutingReports'));
const OteScoutingReport = lazy(() => import('~/src/components/pages/OteScoutingReport'));

const OteGames = lazy(() => import('~/src/components/pages/OteGames'));
const OteGame = lazy(() => import('~/src/components/pages/OteGame'));
const OteTeams = lazy(() => import('~/src/components/pages/OteTeams'));
const OteTeam = lazy(() => import('~/src/components/pages/OteTeam'));
const OtePlayers = lazy(() => import('~/src/components/pages/OtePlayers'));
const OtePlayer = lazy(() => import('~/src/components/pages/OtePlayer'));
const OteSeasons = lazy(() => import('~/src/components/pages/OteSeasons'));
const OteSeason = lazy(() => import('~/src/components/pages/OteSeason'));
const OteEvents = lazy(() => import('~/src/components/pages/OteEvents'));
const OteEvent = lazy(() => import('~/src/components/pages/OteEvent'));
const OteThemes = lazy(() => import('~/src/components/pages/OteThemes'));
const OteTheme = lazy(() => import('~/src/components/pages/OteTheme'));

const OtSelectGames = lazy(() => import('~/src/components/pages/OtSelectGames'));
const OtSelectGame = lazy(() => import('~/src/components/pages/OtSelectGame'));
const OtSelectTeams = lazy(() => import('~/src/components/pages/OtSelectTeams'));
const OtSelectTeam = lazy(() => import('~/src/components/pages/OtSelectTeam'));
const OtSelectPlayers = lazy(() => import('~/src/components/pages/OtSelectPlayers'));
const OtSelectPlayer = lazy(() => import('~/src/components/pages/OtSelectPlayer'));
const OtSelectSeasons = lazy(() => import('~/src/components/pages/OtSelectSeasons'));
const OtSelectSeason = lazy(() => import('~/src/components/pages/OtSelectSeason'));

const Ot7Games = lazy(() => import('~/src/components/pages/Ot7Games'));
const Ot7Game = lazy(() => import('~/src/components/pages/Ot7Game'));
const Ot7Teams = lazy(() => import('~/src/components/pages/Ot7Teams'));
const Ot7Team = lazy(() => import('~/src/components/pages/Ot7Team'));
const Ot7Players = lazy(() => import('~/src/components/pages/Ot7Players'));
const Ot7Player = lazy(() => import('~/src/components/pages/Ot7Player'));
const Ot7Seasons = lazy(() => import('~/src/components/pages/Ot7Seasons'));
const Ot7Season = lazy(() => import('~/src/components/pages/Ot7Season'));
const Ot7Events = lazy(() => import('~/src/components/pages/Ot7Events'));
const Ot7Event = lazy(() => import('~/src/components/pages/Ot7Event'));
const OtxEvents = lazy(() => import('~/src/components/pages/OtxEvents'));
const OtxEvent = lazy(() => import('~/src/components/pages/OtxEvent'));
const OtxFights = lazy(() => import('~/src/components/pages/OtxFights'));
const OtxFight = lazy(() => import('~/src/components/pages/OtxFight'));
const OtxFighters = lazy(() => import('~/src/components/pages/OtxFighters'));
const OtxFighter = lazy(() => import('~/src/components/pages/OtxFighter'));

const Articles = lazy(() => import('~/src/components/pages/Articles'));
const Article = lazy(() => import('~/src/components/pages/Article'));
const Banners = lazy(() => import('~/src/components/pages/Banners'));
const Banner = lazy(() => import('~/src/components/pages/Banner'));
const ShopifyCollections = lazy(() => import('~/src/components/pages/ShopifyCollections'));
const ShopifyCollection = lazy(() => import('~/src/components/pages/ShopifyCollection'));

const Channels = lazy(() => import('~/src/components/pages/Channels'));
const Channel = lazy(() => import('~/src/components/pages/Channel'));

const VideoDistributionVideos = lazy(() => import('~/src/components/pages/VideoDistributionVideos'));
const VideoDistributionVideo = lazy(() => import('~/src/components/pages/VideoDistributionVideo'));

const ProfessionalTeams = lazy(() => import('~/src/components/pages/ProfessionalTeams'));
const ProfessionalTeam = lazy(() => import('~/src/components/pages/ProfessionalTeam'));

const Polls = lazy(() => import('~/src/components/pages/Polls'));
const Poll = lazy(() => import('~/src/components/pages/Poll'));

const OteAiGamePlayerSummaries = lazy(() => import('~/src/components/pages/OteAiGamePlayerSummaries'));
const OteAiGamePlayerSummary = lazy(() => import('~/src/components/pages/OteAiGamePlayerSummary'));

const OteAiPrompts = lazy(() => import('~/src/components/pages/OteAiPrompts'));
const OteAiPrompt = lazy(() => import('~/src/components/pages/OteAiPrompt'));

const OteLiveNotifications = lazy(() => import('~/src/components/pages/OteLiveNotifications'));
const OteLiveNotification = lazy(() => import('~/src/components/pages/OteLiveNotification'));

const OteLiveGameMatches = lazy(() => import('~/src/components/pages/OteLiveGameMatches'));
const OteLiveGameMatch = lazy(() => import('~/src/components/pages/OteLiveGameMatch'));

const AppConfigurations = lazy(() => import('~/src/components/pages/AppConfigurations'));
const AppConfiguration = lazy(() => import('~/src/components/pages/AppConfiguration'));

const NotFound = lazy(() => import('~/src/components/pages/NotFound'));

const LazyElement = ({ element }: { element: React.LazyExoticComponent<any> }) => (
	// createElement(element);
	<Suspended icon={faCircleNotch} size="4x" label="Loading page...">
		{createElement(element)}
	</Suspended>
);

// <Suspended>{createElement(element)}</Suspended>

//This is a stupid hack to get Parcel to put shared element styles in app.css where they belong
//@ts-ignore
const _ = Common;

export const Routes = (
	<Route>
		<Route
			element={
				<Layout>
					<Outlet />
				</Layout>
			}
		>
			<Route path="/" element={<LazyElement key="Index" element={Index} />} />

			<Route path="/videos" element={<LazyElement key="Videos" element={Videos} />} />
			<Route path="/videos/upload" element={<LazyElement key="VideosUpload" element={VideosUpload} />} />
			<Route path="/videos/:id" element={<LazyElement key="Video" element={Video} />} />
			<Route path="/video_groups" element={<LazyElement key="VideoGroups" element={VideoGroups} />} />
			<Route path="/video_groups/:id" element={<LazyElement key="VideoGroup" element={VideoGroup} />} />
			<Route path="/events" element={<LazyElement key="Events" element={Events} />} />
			<Route path="/events/:id" element={<LazyElement key="Event" element={Event} />} />
			<Route path="/camera_access" element={<LazyElement key="CameraAccess" element={CameraAccess} />} />
			<Route path="/camera_users" element={<LazyElement key="CameraUsers" element={CameraUsers} />} />
			<Route path="/items" element={<LazyElement key="Items" element={Items} />} />
			<Route path="/items/:id" element={<LazyElement key="Item" element={Item} />} />
			<Route path="/videographers" element={<LazyElement key="Videographers" element={Videographers} />} />
			<Route path="/videographers/:id" element={<LazyElement key="Videographer" element={Videographer} />} />
			{/* Users */}
			<Route path="/users" element={<LazyElement key="Users" element={Users} />} />
			<Route path="/users/:id" element={<LazyElement key="User" element={User} />} />
			<Route path="/admin_access" element={<LazyElement key="AdminAccess" element={AdminAccess} />} />

			{/* OTE */}
			<Route
				path="/ote_scouting_prospects"
				element={<LazyElement key="OteScoutingProspects" element={OteScoutingProspects} />}
			/>
			<Route
				path="/ote_scouting_prospects/:id"
				element={<LazyElement key="OteScoutingProspect" element={OteScoutingProspect} />}
			/>
			<Route
				path="/ote_scouting_reports"
				element={<LazyElement key="OteScoutingReports" element={OteScoutingReports} />}
			/>
			<Route
				path="/ote_scouting_reports/:id"
				element={<LazyElement key="OteScoutingReport" element={OteScoutingReport} />}
			/>
			<Route path="/ote_games" element={<LazyElement key="OteGames" element={OteGames} />} />
			<Route path="/ote_games/:id" element={<LazyElement key="OteGame" element={OteGame} />} />
			<Route path="/ote_teams" element={<LazyElement key="OteTeams" element={OteTeams} />} />
			<Route path="/ote_teams/:id" element={<LazyElement key="OteTeam" element={OteTeam} />} />
			<Route path="/ote_players" element={<LazyElement key="OtePlayers" element={OtePlayers} />} />
			<Route path="/ote_players/:id" element={<LazyElement key="OtePlayer" element={OtePlayer} />} />
			<Route path="/ote_seasons" element={<LazyElement key="OteSeasons" element={OteSeasons} />} />
			<Route path="/ote_seasons/:id" element={<LazyElement key="OteSeason" element={OteSeason} />} />
			<Route path="/ote_events" element={<LazyElement key="OteEvents" element={OteEvents} />} />
			<Route path="/ote_events/:id" element={<LazyElement key="OteEvent" element={OteEvent} />} />
			<Route path="/ote_themes" element={<LazyElement key="OteThemes" element={OteThemes} />} />
			<Route path="/ote_themes/:id" element={<LazyElement key="OteTheme" element={OteTheme} />} />

			{/* OT Select */}
			<Route path="/ot_select_games" element={<LazyElement key="OtSelectGames" element={OtSelectGames} />} />
			<Route path="/ot_select_games/:id" element={<LazyElement key="OtSelectGame" element={OtSelectGame} />} />
			<Route path="/ot_select_teams" element={<LazyElement key="OtSelectTeams" element={OtSelectTeams} />} />
			<Route path="/ot_select_teams/:id" element={<LazyElement key="OtSelectTeam" element={OtSelectTeam} />} />
			<Route path="/ot_select_players" element={<LazyElement key="OtSelectPlayers" element={OtSelectPlayers} />} />
			<Route path="/ot_select_players/:id" element={<LazyElement key="OtSelectPlayer" element={OtSelectPlayer} />} />
			<Route path="/ot_select_seasons" element={<LazyElement key="OtSelectSeasons" element={OtSelectSeasons} />} />
			<Route path="/ot_select_seasons/:id" element={<LazyElement key="OtSelectSeason" element={OtSelectSeason} />} />

			{/* OT7 */}
			<Route path="/ot7_games" element={<LazyElement key="Ot7Games" element={Ot7Games} />} />
			<Route path="/ot7_games/:id" element={<LazyElement key="Ot7Game" element={Ot7Game} />} />
			<Route path="/ot7_teams" element={<LazyElement key="Ot7Teams" element={Ot7Teams} />} />
			<Route path="/ot7_teams/:id" element={<LazyElement key="Ot7Team" element={Ot7Team} />} />
			<Route path="/ot7_players" element={<LazyElement key="Ot7Players" element={Ot7Players} />} />
			<Route path="/ot7_players/:id" element={<LazyElement key="Ot7Player" element={Ot7Player} />} />
			<Route path="/ot7_seasons" element={<LazyElement key="Ot7Seasons" element={Ot7Seasons} />} />
			<Route path="/ot7_seasons/:id" element={<LazyElement key="Ot7Season" element={Ot7Season} />} />
			<Route path="/ot7_events" element={<LazyElement key="Ot7Events" element={Ot7Events} />} />
			<Route path="/ot7_events/:id" element={<LazyElement key="Ot7Event" element={Ot7Event} />} />

			{/* OTX */}
			<Route path="/otx_fights" element={<LazyElement key="OtxFights" element={OtxFights} />} />
			<Route path="/otx_fights/:id" element={<LazyElement key="OtxFight" element={OtxFight} />} />
			<Route path="/otx_fighters" element={<LazyElement key="OtxFighters" element={OtxFighters} />} />
			<Route path="/otx_fighters/:id" element={<LazyElement key="OtxFighter" element={OtxFighter} />} />
			<Route path="/otx_events" element={<LazyElement key="OtxEvents" element={OtxEvents} />} />
			<Route path="/otx_events/:id" element={<LazyElement key="OtxEvent" element={OtxEvent} />} />

			{/* WWW */}
			<Route path="/articles" element={<LazyElement key="Articles" element={Articles} />} />
			<Route path="/articles/:id" element={<LazyElement key="Article" element={Article} />} />
			<Route path="/banners" element={<LazyElement key="Banners" element={Banners} />} />
			<Route path="/banners/:id" element={<LazyElement key="Banner" element={Banner} />} />
			<Route
				path="/shopify_collections"
				element={<LazyElement key="ShopifyCollections" element={ShopifyCollections} />}
			/>
			<Route
				path="/shopify_collections/:id"
				element={<LazyElement key="ShopifyCollection" element={ShopifyCollection} />}
			/>
			<Route path="/channels" element={<LazyElement key="Channels" element={Channels} />} />
			<Route path="/channels/:id" element={<LazyElement key="Channel" element={Channel} />} />

			{/* Video Distribution */}
			<Route
				path="/video_distribution_videos"
				element={<LazyElement key="VideoDistributionVideos" element={VideoDistributionVideos} />}
			/>
			<Route
				path="/video_distribution_videos/:id"
				element={<LazyElement key="VideoDistributionVideo" element={VideoDistributionVideo} />}
			/>

			{/* Professional Teams */}
			<Route path="/professional_teams" element={<LazyElement key="ProfessionalTeams" element={ProfessionalTeams} />} />
			<Route
				path="/professional_teams/:id"
				element={<LazyElement key="ProfessionalTeam" element={ProfessionalTeam} />}
			/>

			{/* Polls */}
			<Route path="/polls" element={<LazyElement key="Polls" element={Polls} />} />
			<Route path="/polls/:id" element={<LazyElement key="Poll" element={Poll} />} />

			{/* OTE AI Game Player Summaries */}
			<Route
				path="/ote_ai_game_player_summaries"
				element={<LazyElement key="OteAiGamePlayerSummaries" element={OteAiGamePlayerSummaries} />}
			/>
			<Route
				path="/ote_ai_game_player_summaries/:id"
				element={<LazyElement key="OteAiGamePlayerSummary" element={OteAiGamePlayerSummary} />}
			/>

			{/* OTE AI Game Player Summaries */}
			<Route path="/ote_ai_prompts" element={<LazyElement key="OteAiPrompts" element={OteAiPrompts} />} />
			<Route path="/ote_ai_prompts/:id" element={<LazyElement key="OteAiPrompt" element={OteAiPrompt} />} />

			{/* OTE Live */}
			<Route
				path="/ote_live_notifications"
				element={<LazyElement key="OteLiveNotifications" element={OteLiveNotifications} />}
			/>
			<Route
				path="/ote_live_notifications/:id"
				element={<LazyElement key="OteLiveNotification" element={OteLiveNotification} />}
			/>

			{/* OTE Live Game */}
			<Route
				path="/ote_live_game_matches"
				element={<LazyElement key="OteLiveGameMatches" element={OteLiveGameMatches} />}
			/>
			<Route
				path="/ote_live_game_matches/:id"
				element={<LazyElement key="OteLiveGameMatch" element={OteLiveGameMatch} />}
			/>

			<Route path="/app_configurations" element={<LazyElement key="AppConfigurations" element={AppConfigurations} />} />
			<Route
				path="/app_configurations/:id"
				element={<LazyElement key="AppConfiguration" element={AppConfiguration} />}
			/>
		</Route>
		<Route
			path="/video_groups/public/:id"
			element={<LazyElement key="VideoGroupPublic" element={VideoGroupPublic} />}
		/>
		<Route path="/videos/public/:id" element={<LazyElement key="VideosPublic" element={VideosPublic} />} />

		<Route path="*" element={<LazyElement key="NotFound" element={NotFound} />} />
	</Route>
);
