import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { imgOvertimeLogotypeSvg } from '~/src/images';
import { compactJoin } from '~/src/lib';
import { AuthenticationConsumer } from '../contexts/Authentication';
import Avatar from './Avatar';
import './Header.scss';
import { NavConsumer } from './Nav';

const Header = () => (
	<NavConsumer>
		{({ isNavigationVisible, setIsNavigationVisible }) => (
			<header className="Header">
				<div className="LogoContainer">
					<div
						className="MenuIcon"
						onClick={() => {
							setIsNavigationVisible(!isNavigationVisible);
						}}
					>
						{isNavigationVisible ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
					</div>
					<a href="/">
						<img src={imgOvertimeLogotypeSvg} />
					</a>
				</div>
				<div className="UserContainer">
					<AuthenticationConsumer>
						{({ logout, currentUser }) =>
							!currentUser?.id ? null : (
								<>
									<div className="Logout" onClick={logout}>
										Logout
									</div>
									<Avatar user={currentUser} />
								</>
							)
						}
					</AuthenticationConsumer>
					<AuthenticationConsumer>
						{({ socketReadyState }) => (
							<div
								className={compactJoin([
									'SocketStatus',
									typeof WebSocket === 'undefined'
										? 'Closed'
										: socketReadyState === WebSocket?.OPEN
											? 'Open'
											: socketReadyState === WebSocket?.CONNECTING
												? 'Connecting'
												: 'Closed',
								])}
							></div>
						)}
					</AuthenticationConsumer>
				</div>
			</header>
		)}
	</NavConsumer>
);
export default Header;
