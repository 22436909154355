import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Link, Location } from 'react-router-dom';
import { UserType, withAuthentication } from '../contexts/Authentication';
import {
	imgNavArchivesSvg,
	imgNavBasketballSvg,
	imgNavCameraAccessSvg,
	imgNavEventsSvg,
	imgNavFightsSvg,
	imgNavFootballSvg,
	imgNavHeadlinesSvg,
	imgNavPlayersSvg,
	imgNavSeasonsSvg,
	imgNavShopifySvg,
	imgNavTeamsSvg,
	imgNavVideographersSvg,
	imgNavVideosSvg,
	imgOvertimeIconSvg,
} from '../images';
import styles from '../lib/styles';
import './Nav.scss';
import { withLocation } from './WithLocation';

type NavContextType = {
	isNavigationVisible: boolean;
	setIsNavigationVisible: Dispatch<SetStateAction<boolean>>;
};

const defaultContext: NavContextType = {
	isNavigationVisible: false,
	setIsNavigationVisible: () => {},
};
const { Provider, Consumer } = React.createContext(defaultContext);

export const NavProvider = ({ children }) => {
	const [isNavigationVisible, setIsNavigationVisible] = useState(defaultContext.isNavigationVisible);

	return <Provider value={{ isNavigationVisible, setIsNavigationVisible }}>{children}</Provider>;
};

export const NavConsumer = ({ children }) => <Consumer>{children}</Consumer>;

type Page = {
	page: string;
	icon?: string;
	label: string;
	canCreate?: boolean;
	roles?: string[];
};

export const withNav = (Component) => (props) => (
	<NavConsumer>{(navProps) => <Component {...props} {...navProps} />}</NavConsumer>
);

const pages: { [key: string]: (Page | string)[] } = {
	videos: [
		{ page: 'videos', icon: imgNavVideosSvg, label: 'Videos' },
		{ page: 'video_groups', icon: imgNavVideosSvg, label: 'Video Groups', canCreate: true },
		// { page: 'video_uploads', icon: video_uploads, label: 'Video Uploads' },
		{ page: 'events', icon: imgNavEventsSvg, label: 'Events', canCreate: true },
		{
			page: 'camera_access',
			icon: imgNavCameraAccessSvg,
			label: 'Camera Access',
			roles: ['videographers', 'videographers.update'],
		},
		{
			page: 'camera_users',
			icon: imgNavCameraAccessSvg,
			label: 'Camera Users',
			roles: ['videographers', 'videographers.update'],
		},
		{ page: 'items', icon: imgNavArchivesSvg, label: 'Archives' },
		// { page: 'tags', icon: teams, label: 'Teams' },
		{ page: 'videographers', icon: imgNavVideographersSvg, label: 'Videographers' },
	],
	users: [
		{ page: 'users', /* icon: users, */ label: 'Users' },
		{ page: 'admin_access', /* icon: admins, */ label: 'Admins', roles: ['users'] },
	],
	articles: [
		{ page: 'articles', icon: imgNavHeadlinesSvg, label: 'Articles', canCreate: true },
		{ page: 'banners', icon: imgNavEventsSvg, label: 'Banners', canCreate: true },
		{ page: 'shopify_collections', icon: imgNavShopifySvg, label: 'Shopify', canCreate: false },
		{ page: 'channels', /* icon: channels, */ label: 'Channels', canCreate: true },
		// { page: 'youtube_channels', /* icon: youtube_channels, */ label: 'Youtube Channels' },
		// { page: 'youtube_playlists', /* icon: youtube_playlists, */ label: 'Youtube Playlists' },
		// { page: 'youtube_shows', /* icon: youtube_shows, */ label: 'Youtube Shows' },
		// { page: 'youtube_videos', /* icon: youtube_videos, */ label: 'Youtube Videos' },
	],
	ote_scouting_prospects: [
		{ page: 'ote_scouting_prospects', icon: imgNavPlayersSvg, label: 'Prospects', canCreate: true },
		{ page: 'ote_scouting_reports', /* icon: channels, */ label: 'Reports', canCreate: true },
	],
	ote_games: [
		{ page: 'ote_games', icon: imgNavBasketballSvg, label: 'Games', canCreate: true },
		{ page: 'ote_events', icon: imgNavEventsSvg, label: 'Events', canCreate: true },
		{ page: 'ote_teams', icon: imgNavTeamsSvg, label: 'Teams', canCreate: true },
		{ page: 'ote_players', icon: imgNavPlayersSvg, label: 'Players', canCreate: true },
		{ page: 'ote_seasons', icon: imgNavSeasonsSvg, label: 'Seasons', canCreate: true },
	],
	ot_select_games: [
		{ page: 'ot_select_games', icon: imgNavBasketballSvg, label: 'Games', canCreate: true },
		{ page: 'ot_select_teams', icon: imgNavTeamsSvg, label: 'Teams', canCreate: true },
		{ page: 'ot_select_players', icon: imgNavPlayersSvg, label: 'Players', canCreate: true },
		{ page: 'ot_select_seasons', icon: imgNavSeasonsSvg, label: 'Seasons', canCreate: true },
	],
	ot7_games: [
		{ page: 'ot7_games', icon: imgNavFootballSvg, label: 'Games', canCreate: true },
		{ page: 'ot7_events', icon: imgNavEventsSvg, label: 'Events', canCreate: true },
		{ page: 'ot7_teams', icon: imgNavTeamsSvg, label: 'Teams', canCreate: true },
		{ page: 'ot7_players', icon: imgNavPlayersSvg, label: 'Players', canCreate: true },
		{ page: 'ot7_seasons', icon: imgNavSeasonsSvg, label: 'Seasons', canCreate: true },
	],
	otx_fights: [
		{ page: 'otx_fights', icon: imgNavFightsSvg, label: 'Fights', canCreate: true },
		{ page: 'otx_fighters', icon: imgNavPlayersSvg, label: 'Fighters', canCreate: true },
		{ page: 'otx_events', icon: imgNavEventsSvg, label: 'Events', canCreate: true },
	],
	video_distribution_videos: [
		{ page: 'video_distribution_videos', icon: imgNavVideographersSvg, label: 'Videos', canCreate: true },
	],
	professional_teams: [{ page: 'professional_teams', icon: imgNavBasketballSvg, label: 'Teams', canCreate: true }],
	polls: [{ page: 'polls', icon: imgNavBasketballSvg, label: 'Polls', canCreate: true }],
	app_configurations: [
		{ page: 'app_configurations', icon: imgNavCameraAccessSvg, label: 'Configurations', canCreate: false },
	],
};

const sectionLabels = {
	headlines: 'Scores',
	videos: 'Videos',
	users: 'Users',
	articles: 'WWW',
	ote_scouting_prospects: 'OTE Scouting',
	ote_games: 'OTE',
	ot_select_games: 'OT Select',
	ot7_games: 'OT7',
	otx_fights: 'OTX',
	video_distribution_videos: 'Video Distribution',
	professional_teams: 'Professional Teams',
	app_configurations: 'Applications',
	polls: 'Polls',
};

const Nav = ({
	location,
	canAccess,
	currentUser,
	isNavigationVisible,
	setIsNavigationVisible,
}: {
	location: Location;
	canAccess: (string) => boolean;
	currentUser?: UserType;
} & NavContextType) => {
	const mouseOutRef = useRef<NodeJS.Timeout>();
	const navRef = useRef<HTMLElement>();

	const isMobile = () => navRef.current?.getBoundingClientRect().width === document.body.getBoundingClientRect().width;
	useEffect(() => {
		//Auto close menu on mobile
		if (isMobile()) {
			setIsNavigationVisible?.(false);
		}
	}, [location.pathname]);

	const filteredPages = currentUser?.id ? { ...pages } : {};
	Object.keys(filteredPages).forEach(
		(k) =>
			(filteredPages[k] = filteredPages[k].filter((page: Page | string) =>
				typeof page === 'string'
					? true
					: (page.roles ?? []).find((r) => canAccess(r)) || canAccess(page.page + '.read'),
			)),
	);
	Object.keys(filteredPages).forEach(
		(k) =>
			(filteredPages[k] = filteredPages[k].filter(
				(p, i) =>
					!(
						typeof p === 'string' &&
						(typeof filteredPages[k][i + 1] === 'undefined' || typeof filteredPages[k][i + 1] === 'string')
					),
			)),
	);
	Object.keys(filteredPages).forEach((k) => (filteredPages[k].length === 0 ? delete filteredPages[k] : null));

	const navSection = Object.keys(pages).find((k) =>
		pages[k].find((p: Page) => location.pathname.slice(1).split('/').shift() == p.page),
	);

	return (
		<nav
			className={['Nav', isNavigationVisible ? 'Visible' : null].filter(Boolean).join(' ')}
			ref={navRef}
			onMouseOver={() => {
				setIsNavigationVisible(true);
				clearTimeout(mouseOutRef.current);
			}}
			onMouseLeave={() => {
				mouseOutRef.current = setTimeout(() => {
					if (!isMobile()) {
						setIsNavigationVisible(false);
					}
				}, 500);
			}}
		>
			<div>
				{Object.keys(filteredPages).map((section) => (
					<div key={section}>
						<Link to={`/${section}`}>
							<h2>{sectionLabels[section]}</h2>
						</Link>
						<ul className={navSection === section ? 'Visible' : ''}>
							{filteredPages[section].map((page: Page | string) =>
								typeof page === 'string' ? (
									<li className="Section" key={page}>
										{page}
									</li>
								) : (
									<li
										key={page.page}
										className={location.pathname.slice(1).split('/').shift().includes(page.page) ? 'Active' : ''}
									>
										<Link to={`/${page.page}`}>
											<div>
												<img src={page.icon ?? imgOvertimeIconSvg} />
												{page.label}
											</div>
										</Link>
										{page.canCreate && canAccess(page.page + '.create') ? (
											<Link className="Create" to={`/${page.page}/create`}>
												<FontAwesomeIcon icon={faPlusCircle} color={styles.WHITE} />
											</Link>
										) : null}
									</li>
								),
							)}
						</ul>
					</div>
				))}
			</div>
		</nav>
	);
};

export default withAuthentication(withNav(withLocation(Nav)));
