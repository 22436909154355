export default (key) =>
	({
		OVERTIME_AUTH_URL: 'https://api.itsovertime.com',
		OVERTIME_BACKEND_URL: 'https://api.itsovertime.com',
		// OVERTIME_BACKEND_URL: 'http://localhost:8081',
		OVERTIME_IMAGES_CDN: 'https://images.itsovertime.com',
		OVERTIME_VIDEOS_CDN: 'https://videos.itsovertime.com',
		OVERTIME_SHOP_URL: 'https://shop.overtime.tv',
		GOOGLE_API_KEY: 'AIzaSyA4KTRkJbLarz-MOAsd2POYChH3gNB-dzI',
	})[key];
