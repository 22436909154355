import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import * as timeago from 'timeago.js';

export const toDateTimeLocal = (dateString: string) => {
	const date = DateTime.fromISO(dateString);
	return date.toFormat("yyyy-MM-dd'T'HH:mm:ss");
};

export const toDateLocal = (dateString: string, format: string = 'yyyy-MM-dd') => {
	const date = DateTime.fromISO(dateString).setZone('utc');
	return date.toFormat(format);
};

export const formatPhone = (phoneNumber) => {
	if (!phoneNumber) {
		return;
	}
	const phoneFormatted = parsePhoneNumberFromString(phoneNumber);

	const phoneFormattedUs = parsePhoneNumberFromString('+1' + phoneNumber);

	const phoneFormattedIntl = parsePhoneNumberFromString('+' + phoneNumber);

	if (phoneFormatted) {
		return phoneFormatted.formatInternational();
	} else if (phoneNumber.length === 10 && phoneFormattedUs) {
		return phoneFormattedUs.formatInternational();
	} else if (phoneNumber.length > 10 && phoneFormattedIntl) {
		return phoneFormattedIntl.formatInternational();
	} else {
		return phoneNumber;
	}
};

const localeFunc = (number: number, index: number, totalSec: number): [string, string] => {
	const x: [string, string][] = [
		['just now', 'just now'],
		['%ss ago', 'in %s s'],
		['1m ago', 'in 1,'],
		['%sm ago', 'in %sm'],
		['1h ago', 'in 1 hour'],
		['%sh ago', 'in %s hours'],
		['1d ago', 'in 1 day'],
		['%sd ago', 'in %s days'],
		['1w ago', 'in 1 week'],
		['%sw ago', 'in %s weeks'],
		['1mo ago', 'in 1 month'],
		['%smo ago', 'in %s months'],
		['1y ago', 'in 1 year'],
		['%sy ago', 'in %s years'],
	];
	return x[index];
};

timeago.register('en-short', localeFunc);

export const compactJoin = (strings: string[], join = ' ') => strings.filter((s) => !isEmpty(s)).join(join);

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX;

export const colorFromInt = (value: number) =>
	(!isNil(value) ? `#${value.toString(16).padStart(6, '0')}` : null) as Color;

export const capitalize = (text: string) => (isEmpty(text) ? text : text[0].toLocaleUpperCase() + text.substring(1));
export const titleize = (text: string) => (isEmpty(text) ? text : text.split(/_| /).map(capitalize).join(' '));
