import _ from 'lodash';
import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import './WithLocation.scss';

export type PushQuery = (query: Record<string, string>) => void;

export const withLocation = (Component) => (props) => {
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const pushQuery: PushQuery = (query) => {
		//Because multiple component can use this HOC we have to pull from qs
		//TODO: Use a context instead?
		const currentQuery: Record<string, string> = {};
		//JS data structures that don't act like objects/arrays are the worst
		searchParams.forEach((v, k) => (currentQuery[k] = v));

		const newQuery = _.omitBy({ ...currentQuery, ...query }, (v) => v === '' || _.isNil(v));
		if (_.isEqual(currentQuery, newQuery)) {
			return;
		}
		setSearchParams(newQuery);
	};

	return <Component {...props} location={location} query={searchParams} pushQuery={pushQuery} />;
};
